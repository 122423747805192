<template>
    <div>
      <div class="d-flex flex-row justify-content-center">
        <div > <button v-if="isAdmin" type="button" class=" text-center px-4 my-4 mx-2 btn btn-primary" @click="editUnit(unit_id)"> EDIT UNIT DETAILS </button> </div>
        <div > <button v-if="isAdmin" type="button" class=" text-center px-4 my-4 mx-2 btn btn-danger" @click="confirmDeleteUnit(unit_id)"> DELETE UNIT </button> </div>
      </div>
      <div class="my-4">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-horizontal">
                  <div class="card-body">
                    <h5 class="card-title text-center"> {{ unit.name.toUpperCase() }} </h5>
                    <p class="card-text text-center mb-0"> Location: {{ unit.full_location }} </p>
                    <p class="card-text text-center" v-if="unit.developer"> Developer: {{ unit.developer }} </p>
                    <h5 class="card-text text-center fw-bold mt-0" :class="getStatus(unit.status).class"> Status: {{ getStatus(unit.status).status }} </h5>
                    <div class="text-center" role ="button"><img class="img-thumbnail thumbnail-custom h-100 text-center" :src="unit.logo" :alt="unit.name" @click="showModal(unit.logo)"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="container" v-if="unit.description">
              <h5 class="card-title text-center my-4"> DESCRIPTION</h5>
              <p class="card-text text-center"> {{ unit.description }} </p>
            </div>
            <div v-if="unit.all_files">
              <h5 class="card-title text-center my-4"> ALL FILES</h5>
              <div>
                <div class="row">
                  <div class="text-center" v-for="(file, index) in unit.all_files" :key="index">
                    <!-- <img :src="file" left class="img-thumbnail my-auto"/> -->
                    <a :href=file class="text-center" target="_blank"> {{ extractFileName(file) }}</a>
                  </div>
                </div>
              </div>
            </div>

            <h5 class="card-title text-center my-4"> ALL IMAGES</h5>
            <div class="container">
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-lg-3 " v-for="(image, index) in unit.all_images" :key="index" >
                  <img role ="button" @click="showModal(image)" :src="image" left class="card-button img-thumbnail my-auto"/>
                </div>
              </div>
            </div>
            <Modal
              v-show="isModalVisible"
              @close="closeModal"
              :imgsrc="imageToShow"
              :propertyName="unit.name"
            />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import firebase from 'firebase/app'
  import 'firebase/firestore'
  import { checkIfAdmin, deleteUnit, getFileExtension } from '../firebase'
  import Modal from '../components/Modal.vue'
 
  export default {

    components: {
      Modal,
      // pdf
    },
    data() {
      return {
        isModalVisible: false,
        isAdmin: false,
        isLoading: false,
        unit: {},
        unit_id: this.$route.params.unit_id,
        unit_name: this.$route.params.unit_name,
        category_id: this.$route.params.category_id,
        category_name: this.$route.params.category_name,

        width: 600,
        height: 400,
        gallery_items: [],
        imageToShow: null,
        currentId: null,

        showPdf: false,
      }
    },
    name: 'Units',
    created() {
      this.getUnitDetails()
      this.isAdmin = checkIfAdmin()
    },
    methods: {

      showModal(img) {
        this.imageToShow = img
        this.isModalVisible = true;
      },
      closeModal() {
        this.imageToShow = ''
        this.isModalVisible = false;
      },

      editUnit(unit_id) {
        console.log('unit_id', unit_id)
        this.$router.push({ name: 'Edit-Unit',
                            params: {
                              id: this.unit_id,
                              category_name: this.category_name,
                            }
                          })
      },

      extractFileName(url) {
        return decodeURIComponent(url.substring(url.lastIndexOf('%2F') + 3, url.indexOf('?')))
      },

      async confirmDeleteUnit(unit_id) {
        if(confirm(`Delete UNIT? ${this.category_name}, ${this.unit_name}`)) {
          console.log('deleting unit', unit_id, this.category_id)
          this.isLoading = true
          deleteUnit(unit_id, this.category_id).then(() => {
            this.isLoading = false
          })
        } else {
          // do nothing
          return
        }

        // go back to list
        this.goBackToPropertiesList()
      },

      getFileType(file) {
        return getFileExtension(file)
      },

      goBackToPropertiesList() {
        this.$router.push({ name: 'Units',
                            params: { category_id: this.category_id,
                                      category_name: this.category_name
                            }
                          })
      },

      getStatus(status) {
        switch(status) {
          case 1:
          case "1": return {status: 'AVAILABLE', class: ''}
          case 2:
          case "2": return {status: 'ON-HOLD', class: ''}
          case 3:
          case "3": return {status: 'SOLD-OUT', class: 'text-danger'}
          case 4:
          case "4":  return {status: 'READY FOR OCCUPANCY', class: 'text-warning'}
          case 5:
          case "5":  return {status: 'PRE-SELLING', class: 'text-success'}
          default: return {status: 'N/A', class: ''}
        }
      },

      async getUnitDetails() {
        console.log('unit', this.unit_name, this.unit_id)
        await firebase.firestore()
        .collection('units')
        .doc(this.unit_id)
        .get()
        .then(snapshot => {
            this.unit = snapshot.data()
            console.log('retrieved unit', this.unit)
            this.unit.all_images.forEach((img, index) => {
              this.gallery_items.push({ id: index, src: img })
            })
            console.log('this.gallery', this.gallery_items)
            // do something with document
        })
      },
      async deleteImage(index) {
        console.log('clicked', this.unit.all_images[index])
        // await deleteImageInStorage(this.unit.all_images[index])
        // this.unit.all_images.splice(index, 1);
        
      },
    }
  }
</script>

<style>
  /* .img-style {
    height: 200px;
    width: 50%;
  } */
  .card-horizontal {
    display: flex;
    flex: 1 1 auto;
  }

  .thumbnail-custom {
    width: 300px; height: 100px;
    object-fit: cover;
  }

  .delete-icon {
    position: absolute;
    top: 0;
    right: 0;
    background-color: black;
    padding: 4px;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
  }
</style>