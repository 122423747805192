<template>
  <div>
    <h5 class="text-center my-4"> CREATE AN ACCOUNT </h5>
    <div class="card card-body mt-4"> 
        <form @submit.prevent="onSubmit">
            <div class="container">
        <div class="row">
          <div class="col-sm">
            <label>First Name</label>
            <input v-model="form.first_name" class="form-control" required />
          </div>
          <div class="col-sm">
            <label>Middle Name</label>
            <input v-model="form.middle_name" class="form-control" />
          </div>
          <div class="col-sm">
            <label>Last Name</label>
            <input v-model="form.last_name" class="form-control" required />
          </div>
        </div>
      </div>

      <div class="container mt-2">
        <div class="row">
          <div class="col-sm">
            <label>Email</label>
            <input v-model="form.email" class="form-control" type="email" required/>
          </div>
          <div class="col-sm">
            <label>Address</label>
            <input v-model="form.address" class="form-control" type="text"/>
          </div>
          <div class="col-sm">
            <label>Birth Date</label>
            <input v-model="form.birth_date" class="form-control" type="text"/>
          </div>
        </div>
      </div>

      <div class="container mt-2">
        <div class="row">
          <div class="col-sm">
            <label>Agent ID</label>
            <input v-model="form.agent_id" class="form-control" type="text"/>
          </div>
          <div class="col-sm">
            <label>Create Password: </label>
            <input v-model="form.password" class="form-control" type="password"/>
          </div>
        </div>
      </div>


      
      <div class="container mt-2 text-center">
        <div class="row">
          <div class="col-sm">
          <button @click="register" class="btn btn-primary mt-3 mx-3">
            Register Agent
          </button>
          <button @click="this.$router.push('/admin')" class="btn btn-danger mt-3 mx-3">
            Back to Agents List
          </button>
          </div>
        </div>
      </div>
      <!-- <div class="container mt-2 text-center">
        <div class="row">
          <div class="col-sm">
            <button @click="register" class="btn btn-success mt-3">
              Register Agent
            </button>
          </div>
          <div class="col-sm">
            <button @click="this.$router.push('/admin')" class="btn btn-primary mt-3">
              Back to Agents List
            </button>
          </div>

        </div>
      </div> -->
    </form>
    </div>
    </div>

</template>


<script>
import firebase from 'firebase'
import { createUser } from '@/firebase'
// import { reactive } from 'vue'
// import { writeUserData } from '../../firebase'

export default {
  // setup() {
  //   const users = useLoadUsers()
  //   return { users }
  // },
//   props: ['users'],
  data() {
    return {
      errorMsg: false,
      successMsg: false,
      form: {
        last_name: '',
        first_name: '',
        middle_name: '',

        email: '',
        address: '',
        birth_date: '',

        agent_id: '',
        password: '',

        status: 1,
        date_created: new Date(),
        date_updated: null,
        date_removed: null,
      }
    }
  },
  
  methods: {
    userExists(value) {
      console.log('FALSE value', value)
      return false
    },
    async register() {
      console.log('value2', this.userExists(this.form.agent_id))
      if(!this.userExists(this.form.agent_id)) {
        console.log('registering', this.form.email, this.form.password)
        try {
          const userAuth = await firebase.auth().createUserWithEmailAndPassword(this.form.email, this.form.password)
          console.log('userAuth', userAuth)
          await createUser(this.form)
          alert('Successfully registered', this.form)
          this.resetForm()
        } catch(err) {
           console.log('this.form',this.form)
          alert(`ERROR: ${err.message}`)
          console.log('err', err.message)
        }
      } else {
        alert(`AGENT ID ${this.form.agent_id} already used. Please create a unique one.`)
      }
    },
    resetForm() {
      this.form = {
        last_name: '',
        first_name: '',
        middle_name: '',

        email: '',
        address: '',
        birth_date: '',

        agent_id: '',
        password: '',

        status: 1,
        date_created: new Date(),
        date_updated: null,
        date_removed: null,
      }
    }
  }
}
</script>
