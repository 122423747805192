<template>
    <div>
      <div class="my-4 mh-100 ">
      <div> <h2 class="fw-bolder text-center text-primary"> SELECT CATEGORY </h2> </div>
      <div class="text-center"> <button type="button" class="my-4 py-4 btn btn-primary" @click="this.$router.push('/calculator')"> GO TO CALCULATOR </button> </div>
      <section class="features-icons bg-light text-center">
            <div class="container">
                <div class="row">
                    <div role ="button" class="col-lg-3 card-button" v-for="category in categories" :key="category.id" @click="viewCategoryType(category)">
                        <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                            <div v-if="category.id == 1" class="features-icons-icon d-flex"><i class="bi-building m-auto"></i></div>
                            <div v-if="category.id == 2" class="features-icons-icon d-flex"><i class="bi-house m-auto"></i></div>
                            <div v-if="category.id == 3" class="features-icons-icon d-flex"><i class="bi-border m-auto"></i></div>
                            <div v-if="category.id == 4" class="features-icons-icon d-flex"><i class="bi-plus m-auto"></i></div>
                            <h3> {{ category.name }}</h3>
                            <p class="lead mb-0">Click to view {{ `${category.name}` }}</p>
                        </div>
                    </div>
                </div>
            </div>
      </section>
      </div>
    </div>
</template>

<script>
  import firebase from 'firebase'
  import { useLoadCategories } from '../firebase'
 
  export default {
    setup() {
      const categories = useLoadCategories()
      console.log('categories', categories)
      return { categories }
    },
    components: {},
    name: 'Categories',
    data() {
      return {
        iconClass: '',
        selectedFile: null  
      }
    },
    methods: {
      getIconClass(category_id) {
        switch(category_id) {
          case 1: this.iconClass = 'bi-building'; break;
          case 2: this.iconClass = 'bi-house'; break;
          case 3: this.iconClass = 'bi-border'; break;
          case 4: this.iconClass = 'bi-plus'; break;
          default: break;
        }
        console.log('this.iconClass', category_id, this.iconClass)
        return this.iconClass
      },
      uploadImage(e) {
        let file = e.target.files[0];
        var storageRef = firebase.storage().ref('categories/' + file.name)
        storageRef.put(file);
        console.log('uploadImage', e.target.files[0])
      },
      viewCategoryType(category) {
        console.log('view category', category.id)
        this.$router.push({ name: 'Units',
                            params: { category_id: category.id,
                                      category_name: category.name
                            }
                          })
      }
    }
  }
</script>

<style>
  .thumbnail-custom {
    /* width: 300px; height: 300px; */
    object-fit: cover;
  }
  .card-button:hover {
    background-color: #0d6efd;
    opacity: 0.8;
    color: white;
  }
</style>