<template>
  <nav class="main-header navbar navbar-light bg-light static-top my-4">
            <div class="container">
                <div class="">
                    <div v-if="isLoggedIn">
                        <a v-if="isLoggedIn" class="btn navbar-brand text-white" @click="goToPage('Categories')"> PROPERTIES </a>
                        <a v-if="isLoggedIn && checkAdmin()" class="btn navbar-brand text-white" @click="goToPage('Admin')"> USERS/AGENTS </a>
                    </div>
                    <div v-else>
                        <a class="btn navbar-brand text-white" @click="this.$router.push('/')"> HOME </a>
                    </div>
                </div>
                <div v-if="!isLoggedIn"> <a class="btn btn-light" href="#signup" @click="this.$router.push('/#signup')">Sign In</a> </div>
                <div v-else> <a class="btn btn-light" href="#signup" @click="handleSignOut">Logout</a> </div>
            </div>
        </nav>
</template>


<script>
    import firebase from 'firebase'
    import { checkIfAdmin } from '../../firebase'

    export default {
        mounted() {
            firebase.auth().onAuthStateChanged((user) => {
                this.isLoggedIn = user ? true : false
            })
            this.isAdmin = checkIfAdmin()
        },
        created() {
            this.isAdmin = checkIfAdmin()
        },
        data() {
            return {
                isLoggedIn: true,
                isAdmin: false,
            }
        },
        methods: {
            checkAdmin() {
                this.isAdmin = checkIfAdmin()
                return this.isAdmin
            },
            goToPage(linkName) {
                this.$router.push({name: linkName})
            },
            handleSignOut() {
                firebase.auth().signOut().then(() => {
                    alert('Successfully signed out')
                    this.$router.push('/')
                }).catch(error => {
                    alert(error.message)
                    this.$router.push('/')
                })
            }
        }
    }
</script>