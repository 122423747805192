import firebase from 'firebase'
import { ref, onUnmounted } from 'vue'

const config = {
  // apiKey: "AIzaSyBExfkrn0y8kofZQfycspt7Selg1fycVK0",
  // authDomain: "skyland-website.firebaseapp.com",
  // projectId: "skyland-website",
  // storageBucket: "skyland-website.appspot.com",
  // messagingSenderId: "472792661535",
  // appId: "1:472792661535:web:15ff202fce4b38a1cbdf2a",
  // measurementId: "G-S5CLLWFX4V"
  apiKey: "AIzaSyBGxHPR5IYOKMspZ5yDLLnUPMOOazzbu3Y",
  authDomain: "skylandphilippines-60fa8.firebaseapp.com",
  projectId: "skylandphilippines-60fa8",
  storageBucket: "skylandphilippines-60fa8.appspot.com",
  messagingSenderId: "395344242334",
  appId: "1:395344242334:web:bd4bea0f8a81cc83e53d8f",
  measurementId: "G-Z2XXL8NNLR"
};

// const admin = require('firebase-admin');
// const adminStorage = admin.storage();

export const errorCodes = {
  invalidEmail: 'auth/invalid-email',
  userNotFound: 'auth/user-not-found',
  wrongPassword: 'auth/wrong-password'
}

export const errorMsgs = {
  invalidEmail: 'Invalid email',
  userNotFound: 'No account with that email was found',
  wrongPassword: 'Incorrect password',
  defaultErrorMsg: 'Email or password was incorrect'
}

const firebaseApp = firebase.initializeApp(config)

const db = firebaseApp.firestore()
const categoriesCollection = db.collection('categories')
const locationsCollection = db.collection('locations')
const unitsCollection = db.collection('units')
const usersCollection = db.collection('users')
const statusesCollection = db.collection('property_status')

// database fields
export const logo = 'logo'
export const all_images = 'all_images'
export const all_files = 'all_files'

const condominium = 'condominium'
const houseAndLot = 'house-and-lot'
const lotOnly = 'lot-only'
const memorial = 'memorial'
const defaultCategory = 'default-folder-properties'

 const adminEmail = 'skylandphilippines@gmail.com'
//const adminEmail = 'amtpiodos@gmail.com'

export function getAuth() {
  return firebase.auth().currentUser
}

export function checkIfAdmin() {
  return firebase.auth().currentUser && firebase.auth().currentUser.email == adminEmail ? true : false
}


export const createUser = user => {
  return usersCollection.add(user)
}

export const getUser = async id => {
  const user = await usersCollection.doc(id).get()
  return user.exists ? user.data() : null
}

export const updateUser = (id, user) => {
  return usersCollection.doc(id).update(user)
}

export const deleteUser = id => {
  return usersCollection.doc(id).delete()
}

const deleteUnitInDB = id => {
  return unitsCollection.doc(id).delete()
}

export const updateLocation = (id, location) => {
  return locationsCollection.doc(id).update(location)
}

export const deleteLocation = id => {
  return locationsCollection.doc(id).delete()
}

export const useLoadCategories = () => {
  const categories = ref([])
  const close = categoriesCollection.onSnapshot(snapshot => {
    categories.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close)
  return categories
}

export const useLoadLocations = () => {
  const locations = []
  const close = locationsCollection.onSnapshot(snapshot => {
    locations.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close)
  return locations
}

export const useLoadUnits = () => {
  const units = ref([])
  const close = unitsCollection.onSnapshot(snapshot => {
    units.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close)
  return units
}

export const useLoadUsers = async () => {
  const users = ref([])
  await usersCollection.onSnapshot(snapshot => {
    users.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  return users
}

export async function writeUserData(user) {
  await firebase.database().ref('users/' + user.uid).set(user).catch(error => {
    console.log('error adding user to firebase', error.message)
  })
}

export const createLocation = loc => {
  console.log('addingloation', loc)
  return locationsCollection.add(loc)
}

// function to add new units
export async function addNewUnit(unit) {
  let categoryFolder = ''
  switch(unit.category) {
    case '1':
      categoryFolder = condominium
      break
    case '2':
      categoryFolder = houseAndLot
      break
    case '3':
      categoryFolder = lotOnly
      break
    case '4':
      categoryFolder = memorial
      break
    default:
      categoryFolder = defaultCategory
      break
  }
  // to add checking if unit already exists in database
  var id = ''
  await unitsCollection.add({
    name: unit.name,
    category: categoriesCollection.doc(`${unit.category}`),
    location: db.collection('locations').doc(`${unit.location}`),
    full_location: unit.full_location,
    developer: unit.developer,
    description: unit.description,
    status: unit.status,
  })
  .then(async (docRef) => {
    id = docRef.id
    await uploadImage(id, categoryFolder, unit.images)
    // create new folder in firebase with name = name/id separated with '/'
  })
  .finally(() => {
    return id
  })
  return id
}

export async function uploadImage(id, categoryFolder, files) {
  await uploadSingleImage(id, categoryFolder, files.logo)
  await uploadMultipleImages(id, categoryFolder, files.all_images, all_images)
  await uploadMultipleImages(id, categoryFolder, files.all_files, all_files)
}

export async function uploadSingleImage(id, categoryFolder, img, forUpdate) {
  // remove existing logo for update logo
  const folderRef =  firebase.storage().ref().child(`${categoryFolder}/${id}/${logo}/`);
  if(forUpdate) {
    await deleteFolderContents(folderRef)
   }
  firebase.storage().ref(`${categoryFolder}/${id}/${logo}/${img.name}`).put(img).then(response => {
    const prom = Promise.resolve(response.ref.getDownloadURL())
    prom.then((value) => {
      console.log('single image url', value)
      updateImage(id, value, logo)
    })
  })
}

export async function uploadMultipleImages(id, categoryFolder, imagesArray, type) {

  let fieldName = ''
  if(imagesArray && imagesArray.length) {
    const promises = Object.values(imagesArray).map( (img) => {
      switch(type) {
        case all_images: fieldName = all_images; break
        case all_files: fieldName = all_files; break
        default: break;
      }
      return firebase.storage().ref(`${categoryFolder}/${id}/${fieldName}/${img.name}`).put(img)
    })

    const urlArraySnapshots = await Promise.all(promises).catch((err) => console.log(err.code));
    const urlArray = []
    for (const urlSnapshot of urlArraySnapshots) {
      const urlString = await urlSnapshot.ref.getDownloadURL()
      urlArray.push(urlString)
    }

    console.log('urlArray', urlArray)
    await updateImage(id, urlArray, fieldName)
    return urlArray
  }
}

export async function updateImage(id, imgUrl, field) {
    await unitsCollection
      .doc(id)
      .update({
        [field]: imgUrl
      })
      .then(() => {
        console.log('images updated!', field, ':', imgUrl)
      })
}

// function to fetch locations
export async function fetchLocations() {
  var locations = []
  var locations_options = []
  await locationsCollection
    .orderBy("name")
    .get()
    .then(querySnapshot => { locations = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) })
    .finally(() => {
      locations.forEach(location => {
        // console.log('location for each', location)
        locations_options.push({value: location.id, text: location.name})
      })
      // console.log('this.locations options', locations_options)
    })
  return locations_options
}

// function to fetch statuses
export async function fetchStatuses() {
  var statuses = []
  var status_options = []
  await statusesCollection
    .orderBy("status")
    .get()
    .then(querySnapshot => { statuses = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) })
    .finally(() => {
      statuses.forEach(status => {
        // console.log('location for each', location)
        status_options.push({value: status.id, text: status.status})
      })
      // console.log('this.locations options', locations_options)
    })
  return status_options
}

export async function fetchUnitDetails(id) {
  let unit_details = []
  await unitsCollection
    .doc(id)
    .get()
    .then(snapshot => {
        unit_details = snapshot.data()
        console.log('retrieved unit', unit_details)
        // do something with document
  })
  return unit_details
}

export const updateUnitDetails = (id, unit) => {
  console.log('update unit', unit)
  return unitsCollection.doc(id).update(unit)
}

export async function resetPassword(email) {
  console.log('reset pass', email)
  await firebase.auth().sendPasswordResetEmail(email)
  .then(() => {
    alert('Reset password link sent!')
  })
  .catch((error) => {
    console.log(error.code, error.message)
  });
}

export async function deleteUnit(unit_id, category_id) {
  deleteFolderInStore(unit_id, category_id)
}

export async function deleteFolderInStore(unit_id, category_id) {
  const folderName = getFolderName(category_id)
  console.log(unit_id, category_id)
  const folderRef =  firebase.storage().ref().child(`${folderName}/${unit_id}/`);
  if(folderRef) {
    await deleteFolderContents(folderRef).then(() => {
      console.log('going back here to delete folder');
      deleteUnitInDB(unit_id)
     });
  }

  return
}

async function deleteFolderContents(folderRef) {
  await folderRef.listAll().then((res) => {
    const promises = res.items.map((itemRef) => {
      return itemRef.delete().then(() => {
        console.log(`Deleted file: ${itemRef.name}`);
      }).catch((error) => {
        console.error(`Error deleting file: ${itemRef.name}`, error);
      });
    });
    if (res.prefixes) {
      const subPromises = res.prefixes.map((prefixRef) => {
        return deleteFolderContents(prefixRef);
      });
      promises.push(...subPromises);
    }
    return Promise.all(promises);
  }).catch((error) => {
    console.error('Error listing folder contents:', error);
  });
}



export async function deleteImageInStorage(url) {
  const path = url
  .split("o/")[1]
  .split("?")[0]
  .replace(/%2F/g, "/")
  console.log('url', url, path)
  const fileRef = firebase.storage().refFromURL(`gs://skylandphilippines-60fa8.appspot.com/${path}`);
  await fileRef
  .delete()
  .then(() => {
    console.log('delete successfully', fileRef)
  })
  .catch((error) => {
    console.log('error here in deleting', fileRef, error)
  });
}

// async function convertToGsURL(url) {
//   const path = await url
//   .split("o/")[1]
//   .split("?")[0]
//   .replace(/%2F/g, "/")
//   console.log('url', url, path)
//   return firebase.storage().refFromURL(`gs://skylandphilippines-60fa8.appspot.com/${path}`);
// }

export function getFolderName(category_id) {
  switch(category_id) {
    case '1':
      return condominium
    case '2':
      return houseAndLot
    case '3':
      return lotOnly
    case '4':
      return memorial
    default:
      return defaultCategory
  }
}

export function getFileExtension(url) {
  const urlWithoutQueryParams = url.split('?')[0];
  const fileName = urlWithoutQueryParams.substring(urlWithoutQueryParams.lastIndexOf('/')+1);
  const fileExtension = fileName.split('.').pop();

  return fileExtension;
}