<template>
  <div>
    <p class="fw-bolder">Edit AGENT</p>
    <div v-if="!isLoading">
    <div v-if="this.alert_message" class="alert alert-primary"> {{ this.alert_message }} </div>
    <div class="card card-body mt-4">
      
      
      <h5 class="text-center text-danger mt-2 mb-4" v-if="form.original_status == 0" > THIS USER IS INACTIVE </h5>
      <form @submit.prevent="update">
        <div class="container">
        <div class="row">
          <div class="col-sm">
            <label>First Name</label>
            <input v-model="form.first_name" class="form-control" required />
          </div>
          <div class="col-sm">
            <label>Middle Name</label>
            <input v-model="form.middle_name" class="form-control" />
          </div>
          <div class="col-sm">
            <label>Last Name</label>
            <input v-model="form.last_name" class="form-control" required />
          </div>
        </div>
      </div>

      <div class="container mt-2">
        <div class="row">
          <div class="col-sm">
            <label>Address</label>
            <input v-model="form.address" class="form-control" type="text"/>
          </div>
          <div class="col-sm">
            <label>Birth Date</label>
            <input v-model="form.birth_date" class="form-control" type="text"/>
          </div>
          <div class="col-sm">
            <label>Agent ID</label>
            <input v-model="form.agent_id" class="form-control" type="text"/>
          </div>
        </div>
      </div>

      <div class="m-3 text-italic text-info"> Note: Kindly contact your admin to change your email and password.</div>

      <div class="container mt-2 text-center">
        <div class="row">
          <div class="col-sm">
          <button @click="updateDetails()" class="btn btn-primary mt-3 mx-3">
            Update User Details
          </button>
          <button v-if="form.original_status == 1" @click="confirmEnableDisableUser()" class="btn btn-warning mt-3 mx-3">
            Update & DISABLE USER
          </button>
          <button v-else @click="confirmEnableDisableUser" class="btn btn-secondary mt-3 mx-3">
            Update & ENABLE USER
          </button>
          <button disabled @click="deleteUser()" class="btn btn-danger mt-3 mx-3">
            DELETE USER
          </button>
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
    <div v-else class="text-center">
        <h4 class="fw-bolder text-center my-4"> UPDATING... </h4>
        <img class="my-4" src="../assets/gif/loading-gif2.gif">
    </div>
  </div>
</template>

<script>

import { reactive, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getUser, updateUser } from '@/firebase'

export default {
  data() {
    return {
      isLoading: false,
      alert_message: '',
      alert_status: false,
      user_id: this.$route.params.id,
      users: JSON.parse(this.$route.query.users)
    }
  },
  setup() {
    // const users = useLoadUsers()
    const router = useRouter()
    const route = useRoute()
    const userId = computed(() => route.params.id)

    const form = reactive({ last_name: '',
                            first_name: '',
                            middle_name: '',
                            birth_date: '',
                            address: '',
                            agent_id: '',
                            status: '',
                            original_agent_id: '',
                            original_status: ''
                          })
    onMounted(async () => {
      const user = await getUser(userId.value)
      console.log(user, userId.value)
      form.last_name = user.last_name
      form.first_name = user.first_name
      form.middle_name = user.middle_name
      form.birth_date = user.birth_date
      form.address = user.address
      form.agent_id = user.agent_id
      form.status = user.status
      form.original_agent_id = user.agent_id
      form.original_status = user.status
    })

    const update = async () => {
      try {
        await updateUser(userId.value, { ...form })
        setTimeout(() => {
          router.push({ name: 'Admin'})
          form.last_name = '',
          form.first_name = '',
          form.middle_name = '',
          form.birth_date = '',
          form.address = '',
          form.agent_id = ''
        }, 1000)
      } catch(error) {
        console.log('Error', error)
      }
    }

    // const userExists = (value) => {
    //   return users.some(user => user.agent_id == value);
    // }

    return { form, update}
  },
  methods: {
    updateDetails() {
      if(confirm(`UPDATE user? ${this.form.last_name}, ${this.form.first_name} ${this.form.middle_name}`)) {
        this.isLoading = true
        updateUser(this.user_id, this.form)
          .then(() => {
            this.isLoading = false;
            this.$router.push({ name: 'Admin'})
          }).catch((error) => { alert(`UPDATE ERROR ${error.message}`)});
      } else { return }
    },
    userExists(value) {
      console.log('value', value, this.users )
      return this.users.some(user => user.agent_id == value);
    },
    confirmEnableDisableUser() {
      const txt = this.form.original_status ? 'Enable': 'Disable'
      if(confirm(`${txt} user? ${this.form.last_name}, ${this.form.first_name} ${this.form.middle_name}`)) {
        this.isLoading = true
        this.form.status = this.form.original_status == 0 ? 1 : 0
        updateUser(this.user_id, this.form)
          .then(() => {
                this.isLoading = false;
                this.$router.push({ name: 'Admin'})
          }).catch((error) => { alert(`UPDATE ERROR ${error.message}`)});
      } else { return }
    },
    deleteUser() {
      alert(`DELETE UNAVAILABLE for now. Kindly DISABLE USER for the mean time.`)
      // if(confirm(`DELETE user? ${this.form.last_name}, ${this.form.first_name} ${this.form.middle_name}`)) {
      //   this.isLoading = true
      //   this.form.status = 2
      //   updateUser(this.user_id, this.form)
      //     .then(() => {
      //           this.isLoading = false;
      //           this.$router.push({ name: 'Admin'})
      //     }).catch((error) => { alert(`UPDATE ERROR ${error.message}`)});
      // } else { return }
    }
  }
}
</script>
