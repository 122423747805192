<template>
  <label for="text"
          class="block text-xs-left fw-bold uppercase leading-5 text-black px-1 my-1">
          {{ label }}
      </label>
</template>

<script>
  export default ({
      props: ['label']
  })
</script>