<template>
  <div>
    <div v-if="!isLoading">
      <h4 class="fw-bolder text-center"> ADD NEW LOCATION </h4>
      <div>
        <div class="my-2"> <input type="text" v-model="form.name" class="form-input w-100 rounded-md border border-gray-200 w-100 py-2 px-4 text-md"> </div>
       </div>
    </div>

    <div class="text-center">
      <button type="button" class="my-4 py-2 btn btn-primary" @click="submitNewLocation()"> ADD NEW LOCATION </button>
    </div>

    <div class="">
      <div class="row my-3" v-for="(loc, index) in all_locations" :key="loc.value">
        <div class="col cols-4 d-flex align-items-center justify-content-end fs-5 fw-bold"> {{ loc.text }} </div>
        <div class="col cols-3"> <input type="text" v-model="loc.edited_location" class="w-100 form-input rounded-md border border-gray-200 py-2 px-4 text-md"
          @input="updateLastEditedIndex(index)"
          @focus="loc.editing = true" @blur="loc.editing = false">
        </div>
        <div class="col cols-4 d-flex align-items-center">
          <button class="py-1 mx-1 btn btn-success" :disabled="index !== last_edited" @click="editLocation(loc)">Edit</button>
          <button class="py-1 mx-1 btn btn-danger" disabled=true @click="deleteLocation(loc)">Delete</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { createLocation, fetchLocations, updateLocation, deleteLocation } from '@/firebase'
  export default ({
    data() {
      return {
        form: {
          name: '',
        },
        isLoading: false,
        all_locations: [],
        edited_location: '',
        last_edited: null,
      }
    },
    created() {
      this.getLocationsList()
    },
    methods: {
      async submitNewLocation() {
        console.log('Adding new location...')
        try {
          await createLocation(this.form)
          alert('Successfully added new location', this.form.name)
          this.$router.push('/categories')
        } catch(err) {
          console.log('Error adding new location', err.message)
        }
      },
      async getLocationsList() {
        let fetched_locations = await fetchLocations()
        fetched_locations.forEach(loc => {
          this.all_locations.push(loc)
        })
        console.log('fetched locats', fetched_locations)
      },
      async editLocation(location) {
        if(confirm(`Change LOCATION from ${location.text} to ${location.edited_location}?`)) {
        await updateLocation(location.value, { name: location.edited_location })
        alert(`SUCCESSFULLY EDITED location from ${location.text} to ${location.edited_location}`)
        this.$router.push('/categories')
      } else {
        // do nothing
        return
      }
        console.log('EDIT LOC', location)
        this.last_edited = location.text
      },
      updateLastEditedIndex(index) {
        this.last_edited = index
      },
      async deleteLocation(location) {
        if(confirm(`ARE YOU SURE YOU WANT TO DELETE LOCATION? ${location.text}`)) {
          this.isLoading = true
          deleteLocation(location.value)
          // deleteUnit(unit_id, this.category_id).then(() => {
          //   this.isLoading = false
          // })
          this.$router.push('/categories')
        } else {
          // do nothing
          return
        }
      }
    }
  })
</script>
