<template>
  <div class="card mt-4">
    <table class="table m-0">
      <thead>
        <tr>
          <th scope="col">User ID</th>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <!-- AGENT ID -->
          <td class="align-middle text-danger" v-if="user.status == 0"> {{ user.agent_id }}</td>
          <td class="align-middle" v-else> {{ user.agent_id }}</td>

          <!-- NAME -->
          <td class="align-middle text-danger" v-if="user.status == 0">  {{ user.last_name }}, {{ user.first_name }} {{ user.middle_name }}</td>
          <td class="align-middle" v-else> {{ user.last_name }}, {{ user.first_name }} {{ user.middle_name }}</td>
          
          <!-- EMAIL -->
          <td class="align-middle text-danger" v-if="user.status == 0">{{ user.email }} </td>
          <td class="align-middle" v-else> {{ user.email }} </td>

          <!-- ACTIONS -->
          <td class="align-middle">
            <button class="btn btn-primary btn-sm me-2 py-2" @click="goToEdit(user.id)"> Edit </button>
            <button class="btn btn-warning btn-sm me-2 py-2" v-if="user.status == 1" @click="goToEdit(user.id)"> Disable </button>
            <button class="btn btn-secondary btn-sm me-2 py-2" v-else @click="goToEdit(user.id)"> Enable </button>
            <!-- <button class="btn btn-danger btn-sm me-2 py-2" @click="goToEdit(user.id)"> Delete </button> -->
          </td>
        </tr>
      </tbody>
      
      
    </table>
    
    <div v-if="isLoading" class="text-center w-100">
        <h4 class="fw-bolder text-center my-4"> FETCHING... </h4>
        <img class="my-4" src="../../assets/gif/loading-gif2.gif">
      </div>
  </div>
  <p class="fw-bolder mx-2 my-4"> TOTAL AGENTS: {{ users.length}} </p>
</template>

<script>
// import { deleteUser, updateUser } from '@/firebase'
export default {
  props: ['users'],
  methods: {
    goToEdit(userId) {
      console.log('this users', this.users,  JSON.stringify(this.users) )
      this.$router.push({ name: 'Edit',
                          params: { id: userId },
                          query: {
                            users: JSON.stringify(this.users) // Serialize the object as a string to pass it in the query string
                          }
                        })
      // this.$router.push(`/edit/${userId}`)
    }
  }
}
</script>
