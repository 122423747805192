<template>
  <div>
    <section class="call-to-action my-4 text-white text-center" id="signup">
            <div class="container position-relative">
                <div class="row justify-content-center">
                    <div v-if="!isResettingPassword" class="col-xl-6">
                        <h2 class="mb-4">Ready to check properties? Sign-in now!</h2>
                        <div class="my-8">
                            <div class="container text-center">
                                <p> <input type="text" class="form-control w-75 mx-auto" placeholder="Email" v-model="email"/> </p>
                                <p> <input type="password" class="form-control w-75 mx-auto" placeholder="Password" v-model="password"></p>
                                <p v-if="err" class="text-warning fw-bold text-xs"> ERROR: {{ err }}</p>
                                <p type="button" class="bg-secondary text-center w-50 mx-auto" @click="resetPassword(false)"> Reset password?</p>
                                <button type="submit" @click="signin" class="btn btn-success mt-3 px-4 w-50"> Log-In </button>
                            </div>
                        </div>
                    </div>
                    <div v-else class="col-xl-6">
                        <h2 class="mb-4"> FORGOT PASSWORD </h2>
                        <div class="my-8">
                            <div class="container text-center">
                                <p> <input type="text" class="form-control w-75 mx-auto" placeholder="Email" v-model="email"/> </p>
                                <p type="button" class="bg-secondary text-center w-50 mx-auto" @click="resetPassword(true )"> Go Back to Log-in</p>
                                <p v-if="err" class="text-danger fw-bold text-xs"> {{ err }}</p>
                                <button type="submit" @click="forgotPassword(email)" class="btn btn-warning mt-3 px-4 w-50"> Reset Password </button>
                                <!-- <button type="submit" @click="resetPassword(true)" class="btn btn-secondary mt-2 py-1 px-4 w-75"> Go Back to Log-in</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      
  </div>

</template>

<script>
    import firebase from 'firebase'
    import 'firebase/firestore'
    import { errorCodes, errorMsgs, resetPassword } from '../firebase.js'
    // import Header from '../Header.vue'

    export default {
        // setup() {
        //     const users = useLoadUsers()
        //     return { users }
        // },
        components: {
            // Header
        },
        data() {
            return {
                isResettingPassword: false,
                email: '',
                password: '',
                err: '',
                users: [],
            }
        },
        created() {
            this.getUsers()
        },

        methods: {
            getUsers() {
                firebase.firestore()
                .collection("users")
                .orderBy("agent_id")
                .get()
                .then(querySnapshot => this.users = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })))
            },
            userExists(value) {
                return this.users.some(user => user.agent_id == value);
                // return true
            },
            isEmail(value) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailRegex.test(value);
            },
            resetPassword(bool) {
                this.err = null
                this.isResettingPassword = !bool
            },
            forgotPassword(email) {
                email && email != '' ? resetPassword(email) : alert('Please input your email')
                setTimeout(() => {
                    this.isResettingPassword = false
                }, 2000)
            },
            signin() {
                if(this.isEmail(this.email)) {
                    const user = this.users.find(user => user.email === this.email);
                    if(user.status == 1) {
                        this.proceedToSignIn(user.email, this.password)
                    } else if(user.status == 0) {
                        this.err = `USER ${this.email} is not disabled. Pls contact the admin.`
                    } else {
                        this.err = `USER ${this.email} is not registered.`
                    }
                } else {
                    const agent_id = this.email
                    if(!this.userExists(agent_id)) {
                        this.err = `AGENT ID ${agent_id} is not registered.`
                        // alert(`AGENT ID ${agent_id} is not registered.`)
                    } else {
                        const user = this.users.find(user => agent_id === user.agent_id);
                        if(user.status == 1) {
                            this.proceedToSignIn(user.email, this.password)
                        } else if(user.status == 0) {
                            this.err = `USER ${this.email} is not disabled. Pls contact the admin.`
                        } else {
                            this.err = `USER ${this.email} is not registered.`
                        }
                    }
                }
            },
            proceedToSignIn(emailOrUsername, password) {
                console.log('Signing In', emailOrUsername, password)
                    firebase.auth().signInWithEmailAndPassword(emailOrUsername, password)
                    .then(() => {
                        console.log('Successfully signed in', firebase.auth().currentUser)
                        console.log(emailOrUsername)
                        // this.$router.push('/categories') 
                        emailOrUsername == 'skylandphilippines@gmail.com' ? this.$router.push('/admin') : this.$router.push('/categories')
                    }).catch((error) => {
                        console.log('error code', error.code)
                        switch(error.code) {
                            case errorCodes.invalidEmail:
                                this.err = errorMsgs.invalidEmail
                                break
                            case errorCodes.userNotFound:
                                this.err = errorMsgs.userNotFound
                                break
                            case errorCodes.wrongPassword:
                                this.err = errorMsgs.wrongPassword
                                break
                            default:
                                this.err = errorMsgs.defaultErrorMsg
                                break
                        }
                        //   alert(error.message)
                    })
            }
        }
    }
</script>