<template>
    <div class="mt-0">
      <h4 class="fw-bolder text-center"> {{ category_name.toUpperCase() }} </h4>
      <div v-if="!isLoading">
        <div class="container text-center">
          <p class="mb-0"> Select Location: </p>
          <div class="flex flex">
            <select v-model="filtered_location" @change="filterLocation()" class="form-input w-50 border border-gray-200 rounded-md py-2 px-4 text-md">
              <option v-for="option in locations_options" :key="option.value" :value="option.value"> {{ option.text }} </option>
            </select>
          </div>
          <p class="fw-bold mt-2"> TOTAL PROPERTIES: {{ filteredUnits.length }} found </p>
          <div class="text-center" v-if="isAdmin"> <button type="button" class="my-1 py-1 btn btn-primary" @click="goToAddLocation()"> + ADD/EDIT LOCATIONS </button> </div>

        </div>

        <section class="features-icons bg-light text-center">
            <div class="container">
                <div class="row">
                    <div role ="button" class="col-lg-3 py-4 card-button" v-for="unit in filteredUnits" :key="unit.id" type="button" @click="viewSpecificUnit(unit)">
                        <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                          <img class="img-thumbnail thumbnail-custom" :src="unit.logo" v-if="unit.logo" :alt="unit.name">
                          <h5 class="mt-2 mb-0"> {{ unit.name.toUpperCase() }}</h5>
                          <p class="lead mt-0 mb-0">{{ unit.full_location }}</p>
                          <p class="lead mt-0 mb-0 fw-bold" :class="getStatus(unit.status).class" >  {{ getStatus(unit.status).status }} </p>
                        </div>
                    </div>
                </div>
            </div>
      </section>

      </div>
      <div v-else class="text-center">
        <h4 class="fw-bolder text-center my-4"> FETCHING {{ category_name.toUpperCase() }}... </h4>
        <img class="my-4" src="../assets/gif/loading-gif2.gif">
      </div>

      <div class="container mt-2">
        <div class="text-center" v-if="isAdmin"> <button type="button" class="my-4 py-2 btn btn-primary" @click="goToAddUnit"> + ADD NEW UNIT </button> </div>
      </div>
      
      
    </div>

</template>

<script>
  // import { useLoadUnits } from '../firebase'
  import firebase from 'firebase/app'
  import 'firebase/firestore'
  import { checkIfAdmin, fetchLocations } from '../firebase'
 
  export default {
    components: {
    },
    data() {
      return {
        isAdmin: false,
        units: [],
        filtered_location: '',
        filteredUnits: [],
        isLoading: true,
        category_id: this.$route.params.category_id,
        category_name: this.$route.params.category_name,
        locations_options: [{value: 0, text: 'ALL LOCATIONS'}],
      }
    },
    name: 'Units',
    created() {
      this.getUnits()
      this.getLocationsList()
      this.isAdmin = checkIfAdmin()
    },
    methods: {
      clearFilter() {
        this.filteredUnits = []
        this.getUnits()
      },
      filterLocation() {
        this.filteredUnits = []
        if(this.filtered_location) {
          firebase.firestore()
          .collection("units")
          .orderBy("name")
          .get()
          .then(querySnapshot => this.units = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })))
          .finally(() =>
            this.units.forEach(unit=> {
              if(unit.category.id == this.category_id && unit.location.id == this.filtered_location) this.filteredUnits.push(unit)
          }))
        } else {
          this.getUnits()
        }
        
      },
      async getLocationsList() {
        let fetched_locations = await fetchLocations()
        fetched_locations.forEach(loc => {
          this.locations_options.push(loc)
        })
        console.log('fetched lcoation', typeof(fetched_locations), fetched_locations)
      },
      getStatus(status) {
        switch(status) {
          case 1:
          case "1": return {status: 'Available', class: ''}
          case 2:
          case "2": return {status: 'On-hold', class: ''}
          case 3:
          case "3": return {status: 'Sold-out', class: 'text-danger'}
          case 4:
          case "4":  return {status: 'Ready for Occupancy', class: 'text-warning'}
          case 5:
          case "5":  return {status: 'Pre-selling', class: 'text-success'}
          default: return {status: 'N/A', class: ''}
        }
      },
      async getUnits() {
        this.isLoading = true
        await firebase.firestore()
        .collection("units")
        .orderBy("name")
        .get()
        .then(querySnapshot => this.units = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })))
        .finally(() =>
          this.units.forEach(unit=> {
            if(unit.category.id == this.category_id) this.filteredUnits.push(unit)
          })
        );
        this.isLoading = false
      },
      goToAddLocation() {
        this.$router.push({ name: 'Add-Location' })
      },
      goToAddUnit() {
        // console.log('this.category', this.category_name)
        this.$router.push({ name: 'add-new-unit',
                            params: { category_id: this.category_id,
                                      category_name: this.category_name
                            }
                          })
      },
      viewSpecificUnit(unit) {
        console.log('view specific id', unit.id, unit)
        this.$router.push({ name: 'Specific-Unit',
                            params: { unit_id: unit.id,
                                      unit_name: unit.name,
                                      category_id: this.category_id,
                                      category_name: this.category_name
                            }
                          })
      }
    }
  }
</script>

<style>
  .card-button:hover {
    background-color: #0d6efd;
    opacity: 0.8;
    color: white;
  }
  .card-horizontal {
    display: flex;
    flex: 1 1 auto;
  }
  .thumbnail-custom {
    width: 300px; height: 300px;
    object-fit: cover;
  }

</style>
