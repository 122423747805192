<template>
  <div>
    <div v-if="!isLoading">
      <!-- <div v-if="isAdmin"> -->
      <div>
        <!-- <UserCreate/> -->
        <button class="btn btn-success mt-3" @click=" this.$router.push({ name: 'Register'})"> + REGISTER USER/S</button>
        <UsersList :users="filterDeletedUsers"/>
      </div>
      
      <!-- <div class="text-center m-8" v-else> 
        <div class="my-16"> <p class="fw-bold text-white">  </p> </div>
        <div class="my-16"> <p class="fw-bold "> You have no admin rights to access this page.</p> </div>

        <button class="btn btn-primary" @click="goToPage('/')"> Go to homepage </button>
      </div>
    </div>
    <div v-else> -->

    </div>
  </div>
</template>

<script>
// import UserCreate from '@/components/admin/UserCreate.vue'
import UsersList from '@/components/admin/UsersList.vue'
import { checkIfAdmin } from '../firebase'

import firebase from 'firebase/app'
  import 'firebase/firestore'

export default {
  name: 'Home',
  components: {
    // UserCreate, 
    UsersList
  },
  
  // setup() {
  //   const users = useLoadUsers()
  //   return { users }
  // },
  created() {
    this.isAdmin = checkIfAdmin()
    this.getUsers()
  },
  data() {
    return {
      isAdmin: false,
      isLoading: false,
      users: '',
      filterDeletedUsers: '',
    }
  },
  methods: {created() {
    this.isAdmin = checkIfAdmin()
    this.getUsers()
  },
    async getUsers() {
      await firebase.firestore()
        .collection("users")
        .orderBy("agent_id")
        .get()
        .then(querySnapshot => this.users = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })))
        .finally(() =>
          this.filterDeletedUsers = this.users.filter((user) => user.status !== 2)
        );
    },
    goToPage(link) {
      this.$router.push(link)
    }
  }
}
</script>
