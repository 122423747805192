<template>
    <div>
      <div v-id="isAdmin">
        <div v-if="!isLoading">
            <h4 class="fw-bolder text-center"> EDIT {{ category_name }} </h4>
            <div>
            <div class="my-2"> <label-component label="UNIT/PROPERTY NAME"/> <input type="text" v-model="unit.name" class="form-input w-100 rounded-md border border-gray-200 w-100 py-2 px-4 text-md"> </div>
            <div>
                <label-component label="LOCATION"/>
                <select v-model="unit.location" class="form-input w-100 border border-gray-200 rounded-md w-100 py-2 px-4 text-md" required>
                <option v-for="option in locations_options" :key="option.value" :value="option.value"> {{ option.text }} </option>
                </select>
            </div>
            <div class="my-2"> <label-component label="FULL LOCATION"/> <input type="text" v-model="unit.full_location" class="form-input w-100 border border-gray-200 rounded-md w-100 py-2 px-4 text-md"> </div>
            <div class="my-2"> <label-component label="DEVELOPER"/> <input type="text" v-model="unit.developer" class="form-input w-100 border border-gray-200 rounded-md w-100 py-2 px-4 text-md"> </div>
            <div>
                <label-component label="STATUS"/>
                <select v-model="unit.status" class="form-input w-100 border border-gray-200 rounded-md w-100 py-2 px-4 text-md" required>
                  <option v-for="option in status_options" :key="option.value" :value="option.value"> {{ option.text }} </option>
                </select>
            </div>
            <div class="my-2"> <label-component label="DESCRIPTION"/> <textarea  v-model="unit.description" class="form-input w-100 border border-gray-200 rounded-md w-100 py-2 px-4 text-md" /> </div>
            

            <h5 class="card-title text-center my-4"> LOGO </h5>
            <div class="container">
              <div class="row d-flex justify-content-center align-items-center">
                <div role ="button" class="col-lg-3 card-button" v-if="unit.logo">
                  <img :src="unit.logo" left class="img-thumbnail my-auto"/>
                  <!-- <div @click="deleteImage(index)"> DELETE LOGO </div> -->
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center align-items-center">
              <div class="col-md-6 mr-2">
                <h5 class="card-title text-center my-4"> UPLOAD LOGO </h5>
                <form>
                  <div class="form-group">
                    <label for="my-file">Select Logo</label>
                    <input type="file" accept="image/*" @change="previewImage" class="form-control-file" id="my-file">
                    <div class="border p-2 mt-3">
                      <p>LOGO Preview Here:</p>
                      <template v-if="preview">
                        <img :src="preview" class="img-fluid" />
                      </template>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div v-if="unit.all_files">
              <h5 class="card-title text-center my-4"> ALL FILES / DOCUMENTS </h5>
              <div class="container">
                <div class="row">
                  <div class="d-flex text-center justify-content-center align-items-center" v-for="(file, index) in unit.all_files" :key="index">
                    <a :href=file class="text-center mx-4" target="_blank"> {{ extractFileName(file) }}</a>
                    <button class="py-1 my-2 btn btn-danger text-center" @click="deleteFile(index)"> <i class="bi-trash mx-4"> DELETE </i>  </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="cols-12 my-8 mr-2 d-flex justify-content-center align-items-center">
              <div class="col-md-6 mr-2">
                <h5 class="card-title text-center my-4"> UPLOAD FILES </h5>
                <form>
                  <div class="form-group">
                    <label for="my-file">Select Files</label>
                    <input type="file" accept="*/*" multiple="multiple" @change="previewMultiFile" class="form-control-file mx-2" id="my-file">
                    <div class="border p-2 mt-3">
                      <p>FILES Preview Here:</p>
                      <template v-if="files_preview_list.length">
                        <div v-for="(item, index) in files_preview_list" :key="index">
                          <file :src="item" left width="500" height="600"/>
                          <p class="mb-0 fw-bold">{{ index + 1 }}. {{ new_files[index].name }}</p>
                        </div>
                      </template>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <h5 class="card-title text-center my-4"> ALL IMAGES</h5>
            <div class="container my-4">
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-lg-3 d-flex flex-column justify-content-center align-items-center" v-for="(image, index) in unit.all_images" :key="index">
                  <img role ="button" :src="image" left class="card-button img-thumbnail my-auto"/>
                  <button class="py-1 my-2 btn btn-danger text-center" @click="deleteImage(index)"> DELETE </button>
                  <!-- <div @click="deleteImage(index)" class="text-center"> DELETE IMAGE </div> -->
                </div>
              </div>
            </div>

            <div class="ml-2 d-flex justify-content-center align-items-center">
              <form>
                <div class="form-group">
                  <div class="d-flex justify-content-center align-items-center">
                    <label for="my-file" class="text-center fw-bold"> ADD MORE IMAGES </label>
                    <input type="file" accept="image/*" multiple="multiple" @change="previewMultiImage" class="mx-2 form-control-file" id="my-file">
                  </div>
                  <div class="border p-2 mt-2">
                    <p> Preview Here: </p>
                    <template v-if="preview_list.length">
                      <div v-for="(item, index) in preview_list" :key="index">
                        <img :src="item" class="img-fluid" />
                        <p class="mb-0">file name: {{ new_images[index].name }}</p>
                      </div>
                    </template>
                  </div>
                </div>
              </form>
            </div>


            <div class="text-center">
                <button type="button" class="my-4 py-2 btn btn-primary" @click="updateUnitDetails()"> SUBMIT EDIT </button>
            </div>
            </div>
        </div>
        <div v-else class="text-center">
            <h4 class="fw-bolder text-center my-4"> Updating {{ category_name }}... </h4>
            <img class="my-4" src="../../assets/gif/progress-bar.webp">
        </div>
      </div>
      
    </div>
  </template>
  
  <script>
    import { all_files, all_images, fetchLocations, fetchStatuses } from '../../firebase'
    import Label from '@/components/common/Label.vue'
    import {  checkIfAdmin,
              fetchUnitDetails,
              deleteImageInStorage,
              getFolderName,
              uploadMultipleImages,
              uploadSingleImage,
              updateUnitDetails
            } from '../../firebase'
  
    export default ({
      components: {
        'label-component': Label
      },
      async created() {
        this.unit = await fetchUnitDetails(this.unit_id)
        this.getLocationsList()
        this.getStatusList()
        this.isAdmin = checkIfAdmin()
      },
      data () {
        return {
          isAdmin: false,
          
          category: this.$route.params.category_id,
          category_name: this.$route.params.category_name,
          selected: null,
          isLoading: false,
  
          locations: [],
          locations_options: [],

          unit: {},
          unit_id: this.$route.params.id,
          
          preview_list: [],
          new_files: [],
          new_images: [],
          new_logo: '',
          preview: '',
          files_preview_list: [],

          status_options: []
        }
      },
      methods: {
        async deleteFile(index) {
          if(confirm(`DELETE FILE?`)) {
            await deleteImageInStorage(this.unit.all_files[index])
            this.unit.all_files.splice(index, 1);
          } else {
            return
          }
        },
        async deleteImage(index) {
          if(confirm(`DELETE IMAGE?`)) {
            await deleteImageInStorage(this.unit.all_images[index])
            this.unit.all_images.splice(index, 1);
          } else {
            return
          }
        },
        extractFileName(url) {
          return decodeURIComponent(url.substring(url.lastIndexOf('%2F') + 3, url.indexOf('?')))
        },
        async getLocationsList() {
          this.locations_options = await fetchLocations()
          console.log('locations options', this.locations_options)
        },
        async getStatusList() {
          this.status_options = await fetchStatuses()
          console.log('fetch status options', this.status_options)
        },
        async updateUnitDetails() {
          let new_imgUrls = []
          let new_fileUrls = []
          
          this.isLoading = true
          const category_folder = await getFolderName(this.unit.category.id)
          if(this.new_logo) {
            await uploadSingleImage(this.unit_id, category_folder, this.new_logo, true)
          }
          if(this.new_files.length > 0) {
            new_fileUrls = await uploadMultipleImages(this.unit_id, category_folder, this.new_files, all_files)
          }
          if(this.new_images.length > 0) {
            new_imgUrls = await uploadMultipleImages(this.unit_id, category_folder, this.new_images, all_images)
          }
          // change to checking if imgUrl is not empty
          await this.addNewFilesToFilesList(new_fileUrls)
          await this.addNewUrlsToImgList(new_imgUrls)
          await updateUnitDetails(this.unit_id, { ...this.unit })
          setTimeout(() => {
            this.$router.push({ name: 'Specific-Unit',
                        params: { unit_id: this.unit_id,
                                  unit_name: this.unit.name,
                                  category_id: this.category }
            })
          }, 1000)
          
        },
        async addNewFilesToFilesList(new_fileUrls) {
          console.log('new file urls', new_fileUrls)
          new_fileUrls.forEach(url => {
            this.unit.all_files.push(url)
          })
        },
        async addNewUrlsToImgList(new_imgUrls) {
          new_imgUrls.forEach(url => {
            this.unit.all_images.push(url)
          })
          return
        },
        previewMultiImage: function(event) {
          var input = event.target;
          var count = input.files.length;
          var index = 0;
          if (input.files) {
            while(count --) {
              var reader = new FileReader();
              reader.onload = (e) => {
                this.preview_list.push(e.target.result);
              }
            
              this.new_images.push(input.files[index]);
              reader.readAsDataURL(input.files[index]);
              index ++;
            }
          }
        },
        previewMultiFile: function(event) {
          var input = event.target;
          var count = input.files.length;
          var index = 0;
          if (input.files) {
            while(count --) {
              var reader = new FileReader();
              reader.onload = (e) => {
                this.files_preview_list.push(e.target.result);
              }
              this.new_files.push(input.files[index]);
              reader.readAsDataURL(input.files[index]);
              index ++;
            }
          }
        },

        previewImage: function(event) {
        var input = event.target;
        if (input.files) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview = e.target.result;
          }
          // this.image=input.files[0];
          this.new_logo = input.files[0]
          reader.readAsDataURL(input.files[0]);
        }
      },
  
        reset: function() {
          this.image = null;
          this.preview = null;
          this.image_list = [];
          this.preview_list = [];
          this.new_images = []
          this.files_preview_list = []
          this.new_files = []
        }
      }
    })
  </script>