<template>
    <div> 
        <section class="call-to-action my-4 text-white text-center" id="signup">
            <div class="container position-relative">
                <div class="row justify-content-center">
                    <div class="col-xl-6">
                        <h2 class="mb-4">Ready to check properties? Sign-in now!</h2>
                        <div class="my-8">
                            <div class="container text-center">
                                <p> <input type="text" class="form-control w-75 mx-auto" placeholder="Email" v-model="email"/> </p>
                                <p> <input type="password" class="form-control w-75 mx-auto" placeholder="Password" v-model="password"></p>
                                <p v-if="err" class="text-danger fw-bold text-xs"> {{ err }}</p>
                                <button type="submit" @click="signin" class="btn btn-success mt-3 px-4 w-50"> Log-In </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

</template>

<script>
      import firebase from 'firebase'
      import { errorCodes, errorMsgs } from '../firebase.js' 

      export default {
        components: {
        },
        data() {
            return {
                email: '',
                password: '',
                err: ''
            }
          },

          methods: {
              signin() {
                  console.log('registering', this.email, this.password)
                  firebase.auth().signInWithEmailAndPassword(this.email, this.password)
                    .then(() => {
                        console.log('Successfully signed in', firebase.auth().currentUser)
                        this.$router.push('/admin')
                    }).catch((error) => {
                        console.log('error code', error.code)
                        switch(error.code) {
                            case errorCodes.invalidEmail:
                                this.err = errorMsgs.invalidEmail
                                break
                            case errorCodes.userNotFound:
                                this.err = errorMsgs.userNotFound
                                break
                            case errorCodes.wrongPassword:
                                this.err = errorMsgs.wrongPassword
                                break
                            default:
                                this.err = errorMsgs.defaultErrorMsg
                                break
                        }
                        //   alert(error.message)
                      })
              }
          }
      }
</script>