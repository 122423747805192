<template>
  <div class="mb-20">
    <div class="my-2"> <label-component label="TOTAL CONTRACT PRICE"/> <input type="text" pattern="[0-9,.]*" oninput="this.value = this.value.replace(/[^\d.,]/g, '')" inputmode="decimal" v-model="tcp" class="form-input w-100 border border-gray-200 rounded-md py-2 px-4 text-md"> </div>
    <div class="d-flex">
      <div class="my-2 mx-1"> <label-component label="EQUITY/DP %"/> <input type="number" v-model="equity_or_dp_percent" class="form-input w-100 border border-gray-200 rounded-md py-2 px-4 text-md"> </div>
      <div class="my-2 mx-1"> <label-component label="EQUITY/DP AMT"/> <input disabled read-only :value="formatNumber(equity_or_dp_amount)" class="form-input w-100 border border-gray-200 rounded-md py-2 px-4 text-md"> </div>
    </div>
    <div class="d-flex">
      <div class="my-2"> <label-component label="RESERVATION FEE"/> <input type="text" pattern="[0-9,.]*" oninput="this.value = this.value.replace(/[^\d.,]/g, '')" inputmode="decimal" v-model="reservation_fee" class="form-input w-100 border border-gray-200 rounded-md py-2 px-4 text-md"> </div>
      <div class="my-2 mx-1"> <label-component label="EQUITY NET"/> <input disabled read-only :value="formatNumber(equity_net_of_reservation_fee)" class="form-input w-100 border border-gray-200 rounded-md py-2 px-4 text-md"> </div>
    </div>
    <div class="d-flex">
      <div class="my-2"> <label-component label="EQUITY MONTHS"/> <input type="number" v-model="equity_months" class="form-input w-100 border border-gray-200 rounded-md py-2 px-4 text-md"> </div>
      <div class="my-2 mx-1"> <label-component label="EQUITY PAYABLE"/> <input disabled read-only :value="formatNumber(equity_payable)" class="form-input w-100 border border-gray-200 rounded-md py-2 px-4 text-md"> </div>
    </div>
    <div class="my-2"> <label-component label="MISCELLANEOUS FEE"/> <input type="text" pattern="[0-9,.]*" oninput="this.value = this.value.replace(/[^\d.,]/g, '')" inputmode="decimal" v-model="miscellaneous_fee" class="form-input w-100 border border-gray-200 rounded-md py-2 px-4 text-md"> </div>
    <div class="d-flex">
      <div class="my-2"> <label-component label="NO. OF MONTHS"/> <input type="number" v-model="misc_fee_months" class="form-input w-100 border border-gray-200 rounded-md py-2 px-4 text-md"> </div>
      <div class="my-2 mx-1"> <label-component label="PAYABLE"/> <input disabled read-only :value="formatNumber(misc_fee_payable)" class="form-input w-100 border border-gray-200 rounded-md py-2 px-4 text-md"> </div>
    </div>
    <div class="d-flex">
      <div class="my-2"> <label-component label="BALANCE LOANABLE %"/> <input type="number" disabled readonly v-model="balance_loanable_percent" class="form-input w-100 border border-gray-200 rounded-md py-2 px-4 text-md"> </div>
      <div class="my-2 mx-1"> <label-component label="BALANCE LOANABLE AMOUNT"/> <input disabled read-only :value="formatNumber(balance_loanable_amount)" class="form-input w-100 border border-gray-200 rounded-md py-2 px-4 text-md"> </div>
    </div>
    <h6 class="my-4 text-bold text-center"> Sample computations at 6.5% interest rate </h6>
    <div class="my-2">
      <div class="d-flex"> <label-component label="30 YRS" class="mx-2"/> <input disabled readonly :value="getInterestValue(30)" class="form-input w-75 border border-gray-200 rounded-md py-2 px-4 text-md"> </div>
      <div class="d-flex"> <label-component label="25 YRS" class="mx-2"/> <input disabled readonly :value="getInterestValue(25)" class="form-input w-75 border border-gray-200 rounded-md py-2 px-4 text-md"> </div>
      <div class="d-flex"> <label-component label="20 YRS" class="mx-2"/> <input disabled readonly :value="getInterestValue(20)" class="form-input w-75 border border-gray-200 rounded-md py-2 px-4 text-md"> </div>
      <div class="d-flex"> <label-component label="15 YRS" class="mx-2"/> <input disabled readonly :value="getInterestValue(15)" class="form-input w-75 border border-gray-200 rounded-md py-2 px-4 text-md"> </div>
      <div class="d-flex"> <label-component label="10 YRS" class="mx-2"/> <input disabled readonly :value="getInterestValue(10)" class="form-input w-75 border border-gray-200 rounded-md py-2 px-4 text-md"> </div>
      <div class="d-flex"> <label-component label="5 YRS" class="mx-2"/> <input disabled readonly :value="getInterestValue(5)" class="form-input w-75 border border-gray-200 rounded-md py-2 px-4 text-md"> </div>
    </div>

    <div class="text-center"> <button type="button" class="my-4 py-2 btn btn-primary" @click="clearValues"> CLEAR VALUES </button> </div>

  </div>
</template>
       
<script>
  import Label from '@/components/common/Label.vue'
  export default ({
    components: {
      'label-component': Label
    },
    data() {
      return {
        tcp: '',
        equity_or_dp_percent: '',
        equity_or_dp_amount: 0,
        reservation_fee: '',
        equity_net_of_reservation_fee: 0,
        equity_months: '',
        equity_payable: 0,
        miscellaneous_fee: '',
        misc_fee_months: '',
        misc_fee_payable: 0,
        balance_loanable_percent: '',
        balance_loanable_amount: 0
      }
    },
    watch: {
      tcp(newValue) {
        this.tcp = newValue
        this.recalculateValues()
      },
      equity_or_dp_percent(newValue) {
        this.equity_or_dp_percent = newValue
        this.recalculateValues()
      },
      reservation_fee(newValue) {
        this.reservation_fee = newValue
        this.recalculateValues()
      },
      equity_months(newValue) {
        this.equity_months = newValue
        this.recalculateValues()
      },
      miscellaneous_fee(newValue) {
        this.miscellaneous_fee = newValue
        this.recalculateValues()
      },
      misc_fee_months(newValue) {
        this.misc_fee_months = newValue
        this.recalculateValues()
      },
      balance_loanable_amount(newValue) {
        this.balance_loanable_amount = newValue
        this.recalculateValues()
      }
    },
    methods: {
      recalculateValues() {
        const numberTcp = this.reformatToRawValue(this.tcp)
        const numberReservationFee = this.reformatToRawValue(this.reservation_fee)
        const numberMiscellaneousFee = this.reformatToRawValue(this.miscellaneous_fee)

        this.equity_or_dp_amount = numberTcp || this.equity_or_dp_percent ? numberTcp * (this.equity_or_dp_percent) / 100 : 0
        this.equity_net_of_reservation_fee = numberReservationFee ? this.equity_or_dp_amount - numberReservationFee : 0
        this.equity_payable = this.equity_months ? this.equity_net_of_reservation_fee / this.equity_months : 0
        this.misc_fee_payable = this.misc_fee_months || numberMiscellaneousFee ? numberMiscellaneousFee / this.misc_fee_months : 0
        this.balance_loanable_percent = this.equity_or_dp_percent ? 100 - this.equity_or_dp_percent : 100
        this.balance_loanable_amount = numberTcp ? numberTcp * this.balance_loanable_percent / 100 : 0
      },
      reformatToRawValue(value) {
        return parseFloat(value.replace(/,/g, ''));
      },
      getInterestValue(years) {
        let interest_percent = 0
        switch(years) {
          case 30: interest_percent = 0.0063206802 ; break
          case 25: interest_percent = 0.0067520716 ; break
          case 20: interest_percent = 0.0074557314 ; break
          case 15: interest_percent = 0.0087110737 ; break
          case 10: interest_percent = 0.0113547977 ; break
          case 5: interest_percent = 0.0195661482; break

          default: break
        }
        return this.balance_loanable_amount ? this.formatNumber(this.balance_loanable_amount * interest_percent) : 0
      },
      formatDisplay(value) {
        return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : value
      },
      formatNumber(number) {
        console.log(number, typeof(number))
        return number.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      },
      clearValues() {
        this.tcp = ''
        this.equity_or_dp_percent = ''
        this.equity_or_dp_amount = 0
        this.reservation_fee = ''
        this.equity_net_of_reservation_fee  = 0
        this.equity_months = ''
        this.equity_payable = 0
        this.miscellaneous_fee = ''
        this.misc_fee_months = ''
        this.misc_fee_payable = 0
        this.balance_loanable_percent = ''
        this.balance_loanable_amount = 0
      }
    }
  })
</script>