<template>
    <div>
        <div class="bg-light my-4">  </div>
        <!-- Masthead-->
        <header class="masthead">
            <div class="container position-relative">
                <div class="row justify-content-center">
                    <div class="col-xl-6"> 
                    </div>
                </div>
            </div>
        </header>
        
        <!-- Call to Action-->
        <sign-in />


    </div>
  
</template>

<script>
    // import Header from '@/components/Header.vue'
    import SignIn from '@/components/SignIn.vue'
    export default {
        components: {
            SignIn
        }
    }
</script>

<style>
  .img-custom {
    object-fit: cover;
  }

</style>