<template>
  <div class="container">
    
    <navbar-component />
    <router-view />
    <footer-component />
    
  </div>
</template>

<script>
  import Navbar from '@/components/common/Navbar.vue'
  import Footer from '@/components/common/Footer.vue'

  export default {
    components: {
      'footer-component': Footer,
      'navbar-component': Navbar
    }
  }

</script>
