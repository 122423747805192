import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Edit from '@/views/Edit.vue'
import Categories from '@/views/Categories.vue'
import Units from '@/views/UnitsList.vue'
import SpecificUnit from '@/views/SpecificUnit.vue'
import AddNewUnit from '@/views/admin/AddNewUnit.vue'
import EditUnit from '@/views/admin/EditUnit.vue'
import AddLocation from '@/views/admin/AddNewLocation.vue'

import LandingPage from '@/views/LandingPage'
import Feed from '@/views/Feed'
import Register from '@/views/Register'
import SignIn from '@/views/SignIn'

import Calculator from '@/views/Calculator'

import { getAuth } from '../firebase'

const routes = [
  {
    path: '/admin',
    name: 'Admin',
    component: Home,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/edit/:id',
    name: 'Edit',
    component: Edit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/categories',
    name: 'Categories',
    component: Categories,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/category/:category_id/:category_name',
    name: 'Units',
    component: Units,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/category/:category_id/:category_name/:unit_id/:unit_name',
    name: 'Specific-Unit',
    component: SpecificUnit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/units/:category_id/:category_name/add-new-unit',
    name: 'add-new-unit',
    component: AddNewUnit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    name: 'Landing-Page',
    component: LandingPage
  },
  {
    path: '/feed',
    name: 'Feed',
    component: Feed,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/sign-in',
    name: 'Sign-In',
    component: SignIn
  },
  {
    path: '/edit-unit/:id',
    name: 'Edit-Unit',
    component: EditUnit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/calculator',
    name: 'Calculator',
    component: Calculator,
  },
  {
    path: '/add-new-location',
    name: 'Add-Location',
    component: AddLocation,
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  const match = to.matched.some((record) =>  record.meta.requiresAuth)
  if(match) {
    if(getAuth()) {
      next()
    } else {
      alert('Sign in first')
      next({
        name: 'Sign-In'
      })
    }
  } else {
    console.log('no req auth')
    next()
  }
})
export default router
