<template>
  <div>
    
    <div v-if="!isLoading">
      <h4 class="fw-bolder text-center"> ADD NEW {{ category_name.toUpperCase() }} </h4>
      <div>
        <div class="my-2"> <label-component label="UNIT/PROPERTY NAME"/> <input type="text" v-model="new_unit.name" class="form-input w-100 rounded-md border border-gray-200 w-100 py-2 px-4 text-md"> </div>
        <div class="my-2"> <label-component label="CATEGORY/TYPE"/> <input type="text" :value="category_name" readonly disabled class="form-input w-100 border border-gray-200 rounded-md w-100 py-2 px-4 text-md"> </div>
        <div>
          <label-component label="LOCATION"/>
          <select v-model="new_unit.location" class="form-input w-100 border border-gray-200 rounded-md w-100 py-2 px-4 text-md" required>
            <option v-for="option in locations_options" :key="option.value" :value="option.value"> {{ option.text }} </option>
          </select>
        </div>
        <!-- <input type="text" v-model="new_unit.location" class="form-input w-100 border border-gray-200 rounded-md w-100 py-2 px-4 text-md px-4"> -->
        <div class="my-2"> <label-component label="FULL LOCATION"/> <input type="text" v-model="new_unit.full_location" class="form-input w-100 border border-gray-200 rounded-md w-100 py-2 px-4 text-md"> </div>
        <div>
          <label-component label="STATUS"/>
          <select v-model="new_unit.status" class="form-input w-100 border border-gray-200 rounded-md w-100 py-2 px-4 text-md" required>
            <option v-for="option in status_options" :key="option.value" :value="option.value"> {{ option.text }} </option>
          </select>
        </div>
        <div class="my-2"> <label-component label="DEVELOPER"/> <input type="text" v-model="new_unit.developer" class="form-input w-100 border border-gray-200 rounded-md w-100 py-2 px-4 text-md"> </div>
        <div class="my-2"> <label-component label="DESCRIPTION"/> <input type="text" v-model="new_unit.description" class="form-input w-100 border border-gray-200 rounded-md w-100 py-2 px-4 text-md"> </div>

        <div class="d-flex">
          <!-- logo uploader -->
          <div class="col-md-6 mr-2">
            <label-component label="UPLOAD LOGO"/>
            <form>
              <div class="form-group">
                <label for="my-file">Select Logo</label>
                <input type="file" accept="image/*" @change="previewImage" class="form-control-file mx-2" id="my-file">
          
                <div class="border p-2 mt-3">
                  <p>Preview Here:</p>
                  <template v-if="preview">
                    <img :src="preview" class="img-fluid" />
                    <p class="mb-0">File Name: {{ preview.name }}</p>
                    <p class="mb-0">File Size: {{ preview.size/1024 }}KB</p>
                  </template>
                </div>
              </div>
            </form>
          </div>

          <div class="col-md-6 mr-2">
            <label-component label="UPLOAD ALL FILES"/>
            <form>
              <div class="form-group">
                <label for="my-file">Select Files</label>
                <input type="file" accept="*/*" multiple="multiple" @change="previewMultiFile" class="form-control-file mx-2" id="my-file">
                <div class="border p-2 mt-3">
                  <p>Preview Here:</p>
                  <template v-if="files_preview_list.length">
                    <div v-for="(item, index) in files_preview_list" :key="index">
                      <file :src="item" left width="500" height="600"/>
                      <p class="mb-0 fw-bold">{{ index + 1 }}. {{ new_unit.images.all_files[index].name }}</p>
                      <p>{{ new_unit.images.all_files[index].size/1024 }}KB</p>
                    </div>
                  </template>
                </div>
              </div>
            </form>
          </div>
        </div>


          <!-- all images uploader -->
          <div class="ml-2">
            <label-component label="UPLOAD ALL IMAGES"/>
            <form>
              <div class="form-group">
                <label for="my-file">Select Image</label>
                <input type="file" accept="image/*" multiple="multiple" @change="previewMultiImage" class="form-control-file mx-2" id="my-file">
          
                <div class="border p-2 mt-3">
                  <p>Preview Here:</p>
                  <template v-if="preview_list.length">
                    <div v-for="(item, index) in preview_list" :key="index">
                      <img :src="item" class="img-fluid" />
                      <p class="mb-0">file name: {{ preview_list.name }}</p>
                      <p>size: {{ preview_list.size/1024 }}KB</p>
                    </div>
                  </template>
                </div>
              </div>
            </form>
          </div>

        <!-- <div class="w-100"></div> -->
        <div class="col-md-5 mt-3 text-center">
          Reset input file <button @click="reset">Clear All</button>
        </div>
        <div class="text-center">
          <button type="button" class="my-4 py-2 btn btn-primary" @click="submitNewUnit()"> SUBMIT NEW UNIT</button>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <h4 class="fw-bolder text-center my-4"> Adding {{ category_name.toUpperCase() }}... </h4>
      <img class="my-4" src="../../assets/gif/progress-bar.webp">
    </div>
    
    <!-- <div class="text-center">
        <button class="btn btn-primary btn-sm me-2 float-right mt-2" @click="submitNewUnit()"> ADD NEW UNIT </button>
      </div> -->

  </div>
</template>

<script>
  // import firebase from 'firebase'
  import { addNewUnit, fetchLocations, fetchStatuses } from '../../firebase'
  import Label from '@/components/common/Label.vue'
  // import InputForm from '@/components/common/InputForm.vue'

  export default ({
    components: {
      'label-component': Label,
      // 'input-form': InputForm
    },
    created() {
      this.getLocationsList()
      this.getStatuses()
    },
    data () {
      return {
        category_name: this.$route.params.category_name,
        selected: null,
        caption : '',
        img1: '',
        imageData: null,
        isLoading: false,

        locations: [],
        locations_options: [],
        status_options: [],

        new_unit: {
          name: '',
          category: this.$route.params.category_id,
          location: '',
          full_location: '',
          developer: '',
          description: '',
          status: 1,
          images: {
            logo: {},
            all_images: [],
            all_files: [],
          }
        },
        new_unit_id: 0,

        preview: null,
        preview_list: [],
        files_preview_list: []
      }
    },
    methods: {
      click1() {
          this.$refs.input1.click()   
      },
      async getStatuses() {
        this.status_options = await fetchStatuses()
      },
      async getLocationsList() {
        this.locations_options = await fetchLocations()
      },
      async submitNewUnit() {
        this.isLoading = true
        console.log('this.new_unit', this.new_unit)
        this.new_unit_id = await addNewUnit(this.new_unit)
        this.isLoading = false
        this.$router.push({ name: 'Units',
                            params: { category_id: this.new_unit.category,
                                      category_name: this.category_name
                            }
                          })
        // uploadImage(this.new_unit.id, this.new_unit.images)
      },

      previewImage: function(event) {
        var input = event.target;
        if (input.files) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview = e.target.result;
          }
          // this.image=input.files[0];
          this.new_unit.images.logo = input.files[0]
          reader.readAsDataURL(input.files[0]);
        }
      },
      previewMultiImage: function(event) {
        var input = event.target;
        var count = input.files.length;
        var index = 0;
        if (input.files) {
          while(count --) {
            var reader = new FileReader();
            reader.onload = (e) => {
              this.preview_list.push(e.target.result);
            }
            this.new_unit.images.all_images.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
            index ++;
          }
        }
      },
      previewMultiFile: function(event) {
        var input = event.target;
        var count = input.files.length;
        var index = 0;
        if (input.files) {
          while(count --) {
            var reader = new FileReader();
            reader.onload = (e) => {
              this.files_preview_list.push(e.target.result);
            }
            this.new_unit.images.all_files.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
            index ++;
          }
        }
      },
      reset: function() {
        this.image = null;
        this.preview = null;
        this.image_list = [];
        this.preview_list = [];
      }
    }
  })
</script>

<!-- 
<template>
  <div>
      <Header />
      <add-unit-form />
      <div class="container mt-2">
        <div class="row">
          <div class="col-sm">
            <button type="submit" class="btn btn-success mt-3"> Add Unit </button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
  import Header from '@/components/Header.vue'
  import AddUnitFormVue from '../../components/admin/AddUnitForm.vue';

  export default {
    data() {
      return {

      }
    },
    components: {
      Header,
      'add-unit-form': AddUnitFormVue
    }
  }
</script> -->